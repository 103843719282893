import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useBookingClient } from "hooks/useHttpClient";
import {
    BookingIdentifierwithEmail,
    BookingItemModel,
    SendConfirmTicketResponse,
} from "WebApiClient";
import { toast } from "react-toastify";

//mui
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { State } from "rootExports/rootReducer";
import { useSelector } from "react-redux";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons"; // Mail icon
import { faUsers } from "@fortawesome/pro-regular-svg-icons"; // Users icon (Mail Recipients)
import { faFileLines } from "@fortawesome/pro-regular-svg-icons"; // For "Extra Text" (Represents a document/note)

interface ImportBookingModalProps {
    Booking: BookingItemModel;
    Toggle: () => void;
    IsOpen: boolean;
    IsPopUp: boolean;
    // data: BaseApiResponseMetaData | undefined
}

const ConfirmDetailSendModal: React.FC<ImportBookingModalProps> = (props) => {
    const [checkedItems2, setCheckedItems2] = useState<any>({});
    const [checkedItems3, setCheckedItems3] = useState<any>({});
    const [checkedItems4, setCheckedItems4] = useState<any>({});
    const [isSending, setIsSending] = useState(false);
    const bookingClient = useBookingClient();
    const data = props.Booking.latestVersion!;
    const [multiEmail, setMultiEmail] = useState<{ [key: string]: string }[]>([]);
    const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
    const [textAreaDesc, setTextAreaDesc] = useState<string>("");
    const EmailTemplateName =
        props.Booking.latestVersion.bookingIdentifier.templateName || "email.html";

    const [customEmail, setCustomEmail] = useState<string>("");
    const [checkDuplicate, setCheckDuplicate] = useState<string>("0");

    useEffect(() => {
        if (data) {
            getEmailAddress(
                props.Booking?.latestVersion?.flightDetailsInfo?.enhancedRemarks ?? []
            );
        }
    }, [props.IsOpen]);

    function getEmailAddress(array: { remark: string }[]) {
        const emailRegex = /\S+@\S+\.\S+/;
        let foundEmails: { [key: string]: string }[] = [];

        array.forEach((item) => {
            if (
                (item.remark.includes("EM") || item.remark.includes("HE")) &&
                item.remark
            ) {
                const emailMatch = item.remark.match(emailRegex);
                if (emailMatch) {
                    foundEmails.push({
                        [item.remark]: emailMatch[0].toLowerCase().trim(),
                    });
                }
            }
        });

        const uniqueValues: Set<string> = new Set();
        const uniqueArray = foundEmails.filter((obj) => {
            const value = Object.values(obj)[0];
            if (!uniqueValues.has(value)) {
                uniqueValues.add(value);
                return true;
            }
            return false;
        });

        setMultiEmail(uniqueArray);
    }

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isEmailAlreadyAdded = (email: string) => {
        const formattedEmail = email.trim().toLowerCase();
        return multiEmail.some((obj) =>
            Object.values(obj).some(
                (existingEmail) => existingEmail.trim().toLowerCase() === formattedEmail
            )
        );
    };

    const handleCheckboxChange = (email: any) => {
        setCheckedItems({
            ...checkedItems,
            [email]: !checkedItems[email],
        });
    };

    const handleCheckboxChange2 = (email: any) => {
        setCheckedItems2((prevCheckedItems2: any) => {
            // If the email is already present, remove it; otherwise, add it
            if (prevCheckedItems2[email]) {
                const { [email]: _, ...rest } = prevCheckedItems2;
                return rest;
            } else {
                return {
                    ...prevCheckedItems2,
                    [email]: true,
                };
            }
        });
    };

    const handleCheckboxChange3 = (email: any) => {
        setCheckedItems3({
            ...checkedItems3,
            [email]: !checkedItems3[email],
        });
    };
    const handleCheckboxChange4 = (email: any) => {
        setCheckedItems4({
            ...checkedItems4,
            [email]: !checkedItems4[email],
        });
    };

    var otherSettings: string = useSelector((state: State) => {
        if (
            state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
            state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
        ) {
            return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
                "menuPosition=L\nshowFareHeader=Off"
                ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None\n[]\nfalse\nfalse"
                : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None\n[]\nfalse\nfalse\n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
        }
        return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
    });

    let settingArr = otherSettings.split("\n");
    let fieldContainer: string[];

    if (settingArr[7]) {
        try {
            fieldContainer = JSON.parse(settingArr[7])
                .Container.split("#")
                .filter((item: any) => item !== "");
        } catch (error) {
            // Provide a default value or error indicator
            fieldContainer = [];
        }
    } else {
        // Provide a default value or error indicator
        fieldContainer = [];
    }

    async function sendConfirmTicket() {
        if (isSending) return; // Prevent duplicate calls
        setIsSending(true); // Set synchronously to block further clicks

        try {
            // Extract selected emails
            const extractSelectedEmails = (checkedItems: Record<string, boolean>) =>
                Object.keys(checkedItems).filter((email) => checkedItems[email]);

            const finalMail = {
                To: extractSelectedEmails(checkedItems),
                Cc: extractSelectedEmails(checkedItems2),
                Bcc: extractSelectedEmails(checkedItems3),
                default: defaultAddress,
            };

            const Description = textAreaDesc.trim() || null;

            // Validate at least one email is present
            if (
                !finalMail.To.length &&
                !finalMail.Cc.length &&
                !finalMail.Bcc.length
            ) {
                toast.error("Please add at least one email.");
                return;
            }

            const Ids = data?.bookingIdentifier;
            if (!Ids) {
                toast.error("Booking ID is missing.");
                return;
            }

            const requestPayload: BookingIdentifierwithEmail = {
                cartId: Ids.cartId,
                bookingId: Ids.bookingId,
                emailIds: finalMail,
                Description,
                TemplateName: EmailTemplateName,
                replyAddress: props.Booking.latestVersion?.bookingIdentifier?.replyAddress || "",
            };

            const res: any = await bookingClient.SendConfirmTicket(requestPayload);
            const response: SendConfirmTicketResponse = JSON.parse(res);

            if (response.status) {
                toast.success("Email successfully sent");
                setCheckedItems({});
                setCheckedItems2({});
                setCheckedItems3({});
                props.Toggle();
            } else {
                throw new Error("Failed to send email.");
            }
        } catch (error) {
            console.error("Error sending email:", error);
            toast.error("An error occurred while sending the confirmation email.");
        } finally {
            setTimeout(() => setIsSending(false), 500); // Delay reset slightly to prevent rapid re-clicks
        }
    }

    const [defaultAddress, setDefaultAddress] = useState<boolean>(false);

    const handleCustomEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value.trim().toLowerCase();
        setCustomEmail(newEmail);
        setCheckDuplicate(isEmailAlreadyAdded(newEmail) ? "1" : "0");
    };

    const addNewMail = () => {
        setMultiEmail((prevEmails) => {
            const formattedEmail = customEmail.trim().toLowerCase();

            if (!formattedEmail) return prevEmails;

            if (!isValidEmail(formattedEmail)) {
                setCheckDuplicate("2");
                return prevEmails;
            }

            if (isEmailAlreadyAdded(formattedEmail)) {
                setCheckDuplicate("1");
                return prevEmails;
            }

            setCheckDuplicate("0");
            setCustomEmail("");

            return [
                ...prevEmails,
                { [`NewMail${prevEmails.length + 1}`]: formattedEmail },
            ];
        });
    };

    //remove mail from list
    const removeMailFromList = (email: string) => {
        setMultiEmail((prevEmails) => {
            let found = false;
            return prevEmails.filter((obj) => {
                const emailValue = Object.values(obj)[0];
                if (emailValue === email && !found) {
                    found = true;
                    return false;
                }
                return true;
            });
        });
    };

    //textArea
    const handleTextAreaChange = (e: any) => {
        setTextAreaDesc(e.target.value);
    };

    const handleChangeEmailEdit = (selectedMail: string, value: any) => {
        setMultiEmail((prevEmails: any) => {
            return prevEmails.map((obj: any) => {
                if (obj.hasOwnProperty(selectedMail)) {
                    return { [selectedMail]: value };
                }
                return obj;
            });
        });
    };

    return (
        <>
            {!props.IsPopUp ? (
                <Modal size="lg" centered toggle={props.Toggle} isOpen={props.IsOpen}>
                    <ModalHeader toggle={props.Toggle}>
                        <FontAwesomeIcon icon={faEnvelope} size="lg" /> Send confirm details
                        for{" "}
                        <strong>{props.Booking.latestVersion.generalInfo.filekey}</strong>
                    </ModalHeader>
                    <ModalBody>
                        <div className="card">
                            <div className="card-header text-start">
                                <FontAwesomeIcon icon={faFileLines} size="lg" /> EXTRA TEXT
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                            }}
                                        >
                                            <div>
                                                <TextareaAutosize
                                                    onChange={(e) => handleTextAreaChange(e)}
                                                    style={{
                                                        width: "100%",
                                                        height: "80px",
                                                    }}
                                                    aria-label="empty textarea"
                                                    minRows={3}
                                                    maxRows={4}
                                                    placeholder="Enter text here"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-4">
                            <div className="card-header text-start">
                                <FontAwesomeIcon icon={faUsers} size="lg" /> MAIL RECIPIENTS
                            </div>
                            <div className="card-body">
                                <div className="row  p-4">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50%" }}> Mail</th>
                                                <th>To</th>
                                                <th>Cc</th>
                                                <th>Bcc</th>
                                                {/* <th style={{ width: "15%" }}>Reply To</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {multiEmail?.map((data: any, index: any) => {
                                                const email: any = Object.values(data)[0]; // Get the email address
                                                const HE = Object.keys(data)[0];
                                                return (
                                                    <tr
                                                        key={index}
                                                        style={{
                                                            height: "48px",
                                                        }}
                                                    >
                                                        <td>
                                                            <input
                                                                value={email}
                                                                type="text"
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Enter a valid email address"
                                                                onChange={(e) =>
                                                                    handleChangeEmailEdit(HE, e.target.value)
                                                                }
                                                                autoComplete="off"
                                                                tabIndex={index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={checkedItems[email]}
                                                                    onChange={() => handleCheckboxChange(email)}
                                                                    name={email}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={checkedItems2[email]}
                                                                    onChange={() => handleCheckboxChange2(email)}
                                                                    name={email}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={checkedItems3[email]}
                                                                    onChange={() => handleCheckboxChange3(email)}
                                                                    name={email}
                                                                />
                                                            </label>
                                                        </td>

                                                        {Object.keys(data)[0].startsWith("NewMail") && (
                                                            <td>
                                                                <Button
                                                                    onClick={() => removeMailFromList(email)}
                                                                    style={{
                                                                        backgroundColor: "transparent",
                                                                        border: "none",
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faTimesCircle}
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </Button>
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            })}
                                            <tr style={{ height: "48px" }}>
                                                <td>
                                                    <input
                                                        value={customEmail}
                                                        type="text"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter a valid email address"
                                                        onChange={handleCustomEmailChange}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                e.preventDefault();
                                                                addNewMail();
                                                            }
                                                        }}
                                                        onBlur={addNewMail}
                                                    />
                                                    {checkDuplicate === "1" && (
                                                        <p style={{ color: "red", fontSize: "12px" }}>
                                                            Mail is already present
                                                        </p>
                                                    )}
                                                    {checkDuplicate === "2" && (
                                                        <p style={{ color: "red", fontSize: "12px" }}>
                                                            Please enter a valid email
                                                        </p>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="w-100 mt-4 p-0">
                                        <button
                                            className="btn btn-primary w-100"
                                            type="button"
                                            onClick={sendConfirmTicket}
                                        >
                                            Send booking confirmation to selected recipients
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </Modal>
            ) : (
                <div>
                    <div style={{ display: "flex" }}></div>
                    <div className="card">
                        <div className="card-header text-start">
                            <FontAwesomeIcon icon={faFileLines} size="lg" /> EXTRA TEXT
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                        }}
                                    >
                                        <div>
                                            <TextareaAutosize
                                                onChange={(e) => handleTextAreaChange(e)}
                                                style={{
                                                    width: "100%",
                                                    height: "80px",
                                                }}
                                                aria-label="empty textarea"
                                                minRows={3}
                                                maxRows={4}
                                                placeholder="Enter text here"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-4">
                        <div className="card-header text-start">
                            <FontAwesomeIcon icon={faUsers} size="lg" /> MAIL RECIPIENTS
                        </div>
                        <div className="card-body">
                            <div className="row  p-4">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "50%" }}> Mail</th>
                                            <th style={{ textAlign: "center" }}>To</th>
                                            <th style={{ textAlign: "center" }}>Cc</th>
                                            <th style={{ textAlign: "center" }}>Bcc</th>
                                            {/* <th style={{ width: "15%" }}>Reply To</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {multiEmail?.map((data: any, index: any) => {
                                            const email: any = Object.values(data)[0]; // Get the email address
                                            const HE = Object.keys(data)[0];
                                            return (
                                                <tr
                                                    key={index}
                                                    style={{
                                                        height: "48px",
                                                    }}
                                                >
                                                    <td>
                                                        <input
                                                            value={email}
                                                            type="text"
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Enter a valid email address"
                                                            onChange={(e) =>
                                                                handleChangeEmailEdit(HE, e.target.value)
                                                            }
                                                            autoComplete="off"
                                                            tabIndex={index}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedItems[email]}
                                                                onChange={() => handleCheckboxChange(email)}
                                                                name={email}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedItems2[email]}
                                                                onChange={() => handleCheckboxChange2(email)}
                                                                name={email}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedItems3[email]}
                                                                onChange={() => handleCheckboxChange3(email)}
                                                                name={email}
                                                            />
                                                        </label>
                                                    </td>

                                                    {Object.keys(data)[0].startsWith("NewMail") && (
                                                        <td>
                                                            <Button
                                                                onClick={() => removeMailFromList(email)}
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faTimesCircle}
                                                                    style={{ color: "red" }}
                                                                />
                                                            </Button>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                        <tr style={{ height: "48px" }}>
                                            <td>
                                                <input
                                                    value={customEmail}
                                                    type="text"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter a valid email address"
                                                    onChange={handleCustomEmailChange}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            addNewMail();
                                                        }
                                                    }}
                                                    onBlur={addNewMail}
                                                />
                                                {checkDuplicate === "1" && (
                                                    <p style={{ color: "red", fontSize: "12px" }}>
                                                        Mail is already present
                                                    </p>
                                                )}
                                                {checkDuplicate === "2" && (
                                                    <p style={{ color: "red", fontSize: "12px" }}>
                                                        Please enter a valid email
                                                    </p>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="w-100 mt-4 p-0">
                                    <button
                                        className="btn btn-primary w-100"
                                        type="button"
                                        onClick={sendConfirmTicket}
                                    >
                                        Send booking confirmation to selected recipients
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfirmDetailSendModal;

